import styled from '@emotion/styled'
import { Image, Props as ImageProps } from 'app/components/Common/Image'
import { Close } from 'app/components/Common/Modal/Close'
import useLockedBody from 'app/utils/useLockedBody'
import { uniq } from 'lodash'
import React, { memo, useEffect, useState } from 'react'
import { LazyLoadComponent } from 'react-lazy-load-image-component'
import Masonry from 'react-masonry-component'

import { Slider } from './Slider'

export interface Props {
  images: ImageProps[]
  thumbnails?: ImageProps[]
}

export const Gallery = memo(function Gallery({ images, thumbnails }: Props) {
  if (images?.length < 1) {
    return null
  }

  const [_locked, setLocked] = useLockedBody()
  const [modalGalleryStatus, setModalGalleryStatus] = useState(false)
  const [clickedSlide, setClickedSlide] = useState<any>(null)

  useEffect(() => {
    if (modalGalleryStatus) {
      document.addEventListener('keydown', function (e) {
        switch (e.keyCode) {
          case 27:
            setLocked(false)
            setModalGalleryStatus(false)
            setClickedSlide(null)
            break
        }
      })
    }
  }, [modalGalleryStatus])

  return (
    <Container>
      {thumbnails ? (
        <Thumbs
          options={{
            gutter: 2,
            percentPosition: true,
            transitionDuration: 0,
          }}
        >
          {uniq(thumbnails).map((item, index) => (
            <Thumb
              key={index}
              onClick={() => {
                if (!modalGalleryStatus) {
                  setLocked(true)
                  setModalGalleryStatus(true)
                } else {
                  setLocked(false)
                  setModalGalleryStatus(false)
                }

                setClickedSlide(index)
                setTimeout(() => {
                  setClickedSlide(null)
                }, 100)
              }}
            >
              <LazyLoadComponent>
                <Image {...item} />
              </LazyLoadComponent>
            </Thumb>
          ))}
        </Thumbs>
      ) : null}

      {images ? (
        <Modal className={modalGalleryStatus ? ' open' : undefined}>
          <Close
            onClick={() => {
              setLocked(false)
              setModalGalleryStatus(false)
              setClickedSlide(null)
            }}
            variant="secondary"
          />
          <Slider
            clickedSlide={clickedSlide}
            images={images}
            isOpen={modalGalleryStatus}
          />
        </Modal>
      ) : null}
    </Container>
  )
})

const Container = styled.section`
  margin: 12.5rem 0;

  @media (max-width: 767px) {
    margin: 14.734vw 0 24.155vw;
  }

  @media (max-width: 574px) {
    margin: 3.8125rem 0 6.25rem;
  }
`

const Thumbs = styled(Masonry)`
  margin-right: -0.125rem;
  overflow: hidden;

  @media (max-width: 767px) {
    margin-right: 0;
  }
`

const Thumb = styled.div`
  width: calc(25% - 0.125rem);
  height: calc(40vh - 0.125rem);
  float: left;
  background: ${({ theme }) => theme.colors.variants.neutralLight3};
  cursor: pointer;
  overflow: hidden;
  margin-top: 0.125rem;
  position: relative;
  &:hover {
    img {
      transform: scale(1.1);
    }
  }
  &:nth-of-type(1),
  &:nth-of-type(5) {
    height: calc(80vh - 0.125rem);
  }
  &:nth-of-type(3),
  &:nth-of-type(7) {
    width: calc(50% - 0.125rem);
  }

  img {
    width: 101%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
    transition: 0.2s ease-in-out !important;
  }

  @media (max-width: 1199px) {
    height: calc(30vh - 0.125rem);

    &:nth-of-type(1),
    &:nth-of-type(5) {
      height: calc(60vh - 0.125rem);
    }
  }
  @media (max-width: 1023px) {
    height: calc(20vh - 0.125rem);

    &:nth-of-type(1),
    &:nth-of-type(5) {
      height: calc(40vh - 0.125rem);
    }
  }

  @media (max-width: 767px) {
    width: 100%;
    height: 23.4375vh;

    &:nth-of-type(1),
    &:nth-of-type(5) {
      height: 23.4375vh;
    }

    &:nth-of-type(3),
    &:nth-of-type(7) {
      width: 100%;
      height: 23.4375vh;
    }
  }
`

const Modal = styled.div`
  width: 100%;
  height: 100%;
  opacity: 0;
  position: fixed;
  top: 0;
  left: 0;
  visibility: hidden;
  z-index: -1;
  transition: 0.15s ease-out;
  &.open {
    opacity: 1;
    visibility: visible;
    z-index: 9999;
  }
`
